import React from 'react'
import Navbar from '../components/Navbar'
import Contact from '../components/Contact'

export default function contact() {
    return (
    <div>
        <Navbar/>
        <Contact />
    </div>
    )
}
